import { Button, Checkbox, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { convUnixDate, sendDataToServer } from '../utils/functions';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ModalDialog from '../components/Modal/ModalDialog';
import HeaderCom from '../components/HeaderCom';
import ModalJson from '../components/ModalJson';
import SelectorSat from '../components/SelectorSat';


export default function FreqPage(props){

    const [addField, setAddField] = useState("")
    const [freqs, setFreqs] = useState([])
    const [modal, setModal] = useState({})
    const [modalJson, setModalJson] = useState(0)
    const [sat, setSat] = useState("")



    useEffect(() => {
        const fetchData = () => {
            if (addField === "") {
                sendDataToServer({
                    op: "getFreq", sat
                }).then(r => {
                    setFreqs(r);
                });
            }
        };
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 1000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [addField, sat]);



    function handleCchk(lid, state){
        var s = (state ? 1: 0)
        sendDataToServer({op: "setFreqEnable", id: freqs[lid]["id"], state: s}).then(r => {
            if(r["status"]==="OK"){
                freqs[lid]["enable"] = s
                setFreqs([...freqs])
            }
        })
    }

    function deleteFreq(lid){
        sendDataToServer({op: "deleteFreq", id: freqs[lid]["id"]}).then(r => {
            if(r["status"] === "OK"){
                setFreqs(freqs.filter((_, index) => index !== lid));
            }
        })
    }

    function editFreq(col, lid, val){
        var v = prompt("New value '"+col+"':", val)
        //console.log(v)
        if (v !== null && v !== "" && v !== " "){
            sendDataToServer({op: "editFreq", id: freqs[lid]["id"], col, val:v}).then(r => {
                if(r["status"] === "OK"){
                    freqs[lid][col] = v
                    setFreqs([...freqs])
                }
            })
        }
    }

    function upFreq(id){
        sendDataToServer({op: "upFreq", id}).then(r => {
            if(r["status"] === "OK"){
                setFreqs([...freqs])
            }
        })
    }



    return(
        <>

            <HeaderCom title="Frequencies" addons={(
                <>

                    <SelectorSat onChange={e => setSat(e)} />

                    <TextField
                        onChange={e => setAddField(e.target.value)}
                        value={addField}
                        size={"small"}
                        placeholder='13.0:S:11554:H:27500'
                    />
                    <Button
                        style={{margin:"3px 0 0 10px"}}
                        onClick={() => sendDataToServer({"op": "addFreq", addField}).then(r => {
                            if(r["status"]==="NOK"){
                                alert("Error added!")
                            }
                            setAddField("")
                        })}
                    >Add</Button>
                </>
            )} />

            <table width="100%" className="table_style">
                <thead><tr>
                    <td align="center">On</td>
                    <td>#</td>
                    <td>Sat</td>
                    <td>Type</td>
                    <td align="center">TP</td>
                    <td>Comment</td>
                    <td align="center">Cnt</td>
                    <td align="right">Add</td>
                    <td align="right">Start</td>
                    <td align="right">Stop</td>
                    <td align="right">JSON</td>
                    <td align="right">OPTION</td>
                </tr></thead>
                <tbody>

                {freqs.map((v, k) => (
                    <tr key={k} className={(v["busy"] === "1" ? "freq-busy" : "")}>
                        <td align="center">
                            <Checkbox size="small"
                                checked={parseInt(v["enable"])===1}
                                style={{padding:"2px"}}
                                onChange={e => handleCchk(k, e.target.checked)} 
                            />
                        </td>
                        <td>{v["id"]}</td>
                        <td><span className='tipa-a' onClick={()=>editFreq("sat", k, v["sat"])}>{v["sat"]}</span></td>
                        <td><span className='tipa-a' onClick={()=>editFreq("type", k, v["type"])}>DVB-{v["type"]}</span></td>
                        <td align="center"><span className='tipa-a' onClick={()=>editFreq("tp", k, v["tp"])}>{v["tp"]}</span></td>
                        <td><span className='tipa-a' onClick={()=>editFreq("comment", k, v["comment"])}>{v["comment"]}</span></td>
                        <td align="center"><span className='tipa-a' onClick={()=>setModal(
                            { op:"channels_tp", id: v["id"]}
                        )}>{v["cnt"]}</span></td>
                        <td align="right">{ convUnixDate(v["time_add"])}</td>
                        <td align="right">{ convUnixDate(v["time_start"])}</td>
                        <td align="right">{ convUnixDate(v["time_stop"])}</td>
                        <td align="right"><span className='tipa-a' onClick={() => setModalJson(parseInt(v["id"])) }>{v["json"]}</span></td>
                        <td align="right">
                            <ArrowCircleUpIcon className='tipa-a' onClick={() => upFreq(v["id"])} />
                            <ClearIcon className='tipa-a' onClick={() => deleteFreq(k)} />
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>


            <ModalDialog config={modal} />
            <ModalJson id={modalJson} onClose={e => setModalJson(e)} />
            
        </>
    )

}