import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sendDataToServer } from "../utils/functions";


export default function SelectorSat({onChange}){

    const [sat, setSat] = useState({})
    const [state, setState] = useState("")

    useEffect(() => {
        sendDataToServer({op:"getUsals"}).then(r => {
            if(r["status"] === "OK"){
                setSat(r["data"])
                //console.log(r["data"])
            }
        })
    }, [])

    function handleChange(e){
        onChange(e.target.value)
        setState(e.target.value)
    }

    return (
        <FormControl  variant="standard" sx={{ mt:1, mr:2, minWidth: 250 }}>
        <Select
            labelId="sat-simple-select-label"
            id="sat-simple-select"
            value={state}
            label="Select-sat"
            onChange={handleChange}
        >
            <MenuItem value="">---</MenuItem>
            {
                Object.keys(sat).map((v, k) => (
                    <MenuItem value={sat[v]["sat"]} key={k}><span className={sat[v]["enable"]==="0" ? "color-gray" : parseInt(sat[v]["count_upd"])>0 ? "freq-busy" : ""}>[{sat[v]["sat"]}] {sat[v]["comment"]} ({sat[v]["_all"]})</span></MenuItem>
                ))
            }
        </Select>
        </FormControl>
    )
}