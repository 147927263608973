import React, { useEffect, useState } from "react";
import { convUnixDate } from "../../utils/functions";
import ServiceTypeId from "../ServiceTypeId";
import ServiceCaids from "../ServiceCaids";



export default function TableChannels({data}){

    const [vall, setVall] = useState({})

    var keys = Object.keys(data)

    useEffect(()=>{
        if(keys.length > 0){
            setVall(data[keys[0]])
            //console.log(values)
        }
    // eslint-disable-next-line
    }, [])

    return (
        <>
             <table width="100%" className="table_style">
                <thead><tr style={{fontSize:"14px"}}><th>#</th>{(

                    Object.keys(vall).map((v, k) => (
                        <th key={k}>{v.toUpperCase()}</th>
                    ))

                )}</tr></thead>
                <tbody>
                    
                    {(
                        Object.keys(data).map((v, k) => (
                            <tr key={k}><td>{k+1}</td>{(
                                Object.keys(vall).map((vv, kk) => (
                                    <td key={kk}>{(vv.includes("time_") ?
                                        convUnixDate(data[v][vv]) :
                                        vv.includes("type") ?
                                        (<ServiceTypeId id={data[v][vv]} />) :
                                        vv.includes("caid") ?
                                        (<ServiceCaids caid={data[v][vv]} />) :
                                        data[v][vv])}
                                    </td>
                                ))
                            )}</tr>
                        ))
                    )}
                    
                </tbody>
            </table>
        </>
    )

}