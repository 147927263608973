import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import JsonViewer from "./JsonPretty";
import { sendDataToServer } from "../utils/functions";


export default function ModalJson({id, onClose}){

    const [data, setData] = useState()


    useEffect(() => {
        if(id > 0){
            sendDataToServer({op: "getAnalyze", id}).then(r => {
                if(r["status"] === "OK"){
                    setData(r["data"])
                }
            })
        }
    }, [id])

    return (
        <>
        <Dialog open={id > 0 ? true : false} >
            <DialogContent>

                <JsonViewer jsonData={data} />

            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                navigator.clipboard.writeText(data)
                onClose(0)
            }} color="secondary">
                Copy & close
            </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}