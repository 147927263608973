import React from 'react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';  // Можно использовать различные темы

export default function JsonViewer({ jsonData }) {
    return (
        <div>
            <JSONPretty data={jsonData} style={{ fontSize: '11px' }} />
        </div>
    );
};