import { Paper } from '@mui/material'
import React from 'react'
import TableLastScanTp from '../components/Main/TableLastScanTp'





export default function MainPage(props){



    return( 
        <>
            <Paper style={{marginTop:"30px", padding:"20px 30px"}}>
                <TableLastScanTp />
            </Paper>
        </>
    )
}