import React from 'react'
import RedirectHome from '../utils/RedirectHome'
import { Paper } from '@mui/material'



export default function NotFoundPage(props){

    return(
        <>
            <Paper style={{marginTop:"30px", padding:"20px 30px"}}>
                <h2>ERROR 404! Not found!</h2>
                <RedirectHome />
            </Paper>
        </>
    )
}