import React, {  useState } from "react";
import { refresh, sendDataToServer } from "../utils/functions";
import HeaderCom from "../components/HeaderCom"
import { Button, TextField } from "@mui/material";
import TableShow from "../components/TableShow";

export default function UsalsPage(){


    const [addField, setAddField] = useState("")


    function sendAdd(){
        sendDataToServer({"op": "addUsals", addField}).then(r => {
            if(r["status"]==="NOK") alert("Error added!")
            refresh("")
        })
    }


    return (
        <>
        
        
            <HeaderCom title="Usals command" addons={(
                <>
                    <TextField
                        onChange={e => setAddField(e.target.value)}
                        value={addField}
                        size={"small"}
                        placeholder='13.0:D12D or 13.0'
                        onKeyDown={e => {
                            if(e.key === 'Enter'){
                                sendAdd()
                            }}
                        }
                    />
                    <Button
                        style={{margin:"3px 0 0 10px"}}
                        onClick={() => {
                            if (addField !== ""){
                                sendAdd()
                            }}}
                    >Add</Button>
                </>
            )} />


            <TableShow table="usals" scheme={
                {enable: "On", sat: "SAT", command: "Command", comment: "Comment", count_upd: "Count update", _all: "All", time_upd:"Time update"} 
            } update={1} />
        
        </>
    )

}