import './App.css';
import * as React from 'react';
import { Box, Container } from '@mui/material';
import MenuTop from './components/MenuTop';
import Routes from './utils/Routes'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store'; // подключаем хранилище







function App() { 

  const currentYear = new Date().getFullYear();

  useEffect(function(){
    document.title = "DVBSCAN v" + process.env.REACT_APP_SERVER_VER
  }, [])

  return (
    <>
    <Provider store={store}>
    <MenuTop />

    <Container maxWidth="xl"> 
        
        <Routes />

        <Box sx={{
              //bgcolor: '#a3aab1',
              boxShadow: 0,
              borderRadius: 2,
              p: 2,
              minWidth: 300,
              mt:2,
              textAlign:'center',
              fontSize:'12px',
              //color:'text.secondary'
            }}>
            <div>&copy;2023-{currentYear} Copyright <Link className="tipa-a" to="https://dvbscan.com">DVBSCAN.COM</Link> v{process.env.REACT_APP_SERVER_VER}. All rights reserved.</div>
            <div>Powered by <Link className="tipa-a" to="https://cesbo.com" target="_blank" rel="noopener noreferrer">Cesbo Astra</Link>. Our group <Link className="tipa-a" to="https://t.me/dvbscan" target="_blank">@dvbscan</Link></div>
        </Box>
    </Container>

    </Provider>

    </>
  );
}

export default App; 
