import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sendDataToServer } from "../../utils/functions";
import TableChannels from "./TableChannels";


export default function ModalDialog({config}){

    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])
    

    useEffect(() => {
        if (config["op"] !== undefined){
            sendDataToServer(config).then(r => {
                if(r["status"] === "OK"){
                    setOpen(true)
                    //console.log(r["data"])
                    setData(r["data"])
                }
            })
        }
    // eslint-disable-next-line
    }, [config])




    const handleClose = () => {
      setOpen(false);
    };




    return (

        <>
            <Dialog open={open} fullWidth maxWidth="lg"  >
            <DialogTitle>
                {config["op"]} #{config["id"]}
            </DialogTitle>
            <DialogContent>
                <TableChannels data={data} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>Close</Button>
            </DialogActions>
            </Dialog>

        </>

    )

}