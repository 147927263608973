import React, { useState } from 'react'
import LoginForm from '../components/LoginForm'
import Cookies from 'js-cookie';
import { md5hash, sendDataToServer } from '../utils/functions';
import { Alert, Snackbar } from '@mui/material';



export default function LoginPage(props){
    var opt = {}

    const [open, setOpen] = useState(false)

    
    function handleClose(event, reason){
        setOpen(false);
    };


    return(
        <>
            <LoginForm onSubmit={(data) => {
                data["password"] = md5hash(data["password"])
                const newdata = {...data, 'op': "login"}
                if (data.remember){
                    opt.expires = 60
                }
                if(process.env.REACT_APP_DEBUG==="0"){
                    opt.secure = true
                    opt.sameSite = "Strict"
                    opt.domain = "dvbscan.com"
                    opt.path = "/"
                }


                sendDataToServer(newdata).then(r => {
                    if(r.hasOwnProperty("error")){
                        setOpen(true);
                    }else{
                        Cookies.set('user', r.user, opt)
                        Cookies.set('rank', r.rank, opt)
                        Cookies.set('hash', r.hash, opt)
                        window.location.href="/"
                    }
                })
            }} />

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Login / password incorrect!
                </Alert>
            </Snackbar>
        </>
    )
}