import { Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { sendDataToServer } from "../utils/functions";


export default function HeaderCom({title, addons}){

    const [scan, setScan] = useState(false)

    useEffect(() => {
        sendDataToServer({op:"getScanState"}).then(r=>{
            if(r["status"] === "OK" && r["state"] === "1"){
                setScan(true)
            }else{
                setScan(false)
            }
        })
    }, [])


    function scanEn(state){
        sendDataToServer({op:"setScanState", state:state?"1":"0"}).then(r => {
            if (r["status"] === "OK"){
                setScan(state)
            }
        })
    }


    return (
        <>
            <Grid container>
                <Grid item xs={4}>
                    <h2>
                        <ArrowRightIcon style={{ marginRight: "10px" }} />
                        {title}
                    </h2>
                </Grid>
                <Grid item xs={8} style={{textAlign:"right", marginTop:"20px"}}>
                    <span style={{paddingRight:"20px"}}>
                        <Checkbox 
                            checked={scan}
                            onClick={e => scanEn(e.target.checked)}
                        /> scan</span>
                    {addons}
                </Grid>
            </Grid>

            <hr />
        </>
    )

}