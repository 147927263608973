import React, { useState } from 'react'
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button} from "@mui/material"
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { sendDataToServer } from '../utils/functions';



export default function ModalForm({mode, title, data, table, onSave}){

    const [open, setOpen] = useState(false)


    const [formData, setFormData] = useState(data);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
          ...formData,
          [name]: value,
        })
    }

    const handleSubmit = () => {
        sendDataToServer({op:"modalForm", data: formData, table}).then(r => {
            if (r["status"] === "OK"){
                setOpen(false)
                onSave(true)
            }else{
                onSave(false)
            }
        })
    };

    function handleClose(){
        setOpen(false)
    }
    function handleOpen(){
        setOpen(true)
    }


    return (
        <>

        {mode === "add" ? (
            <ControlPointIcon className="tipa-a sm" onClick={handleOpen} />
        ) : (
            <BorderColorIcon className="tipa-a sm" onClick={handleOpen} />
        )}
        
        <Dialog open={open} >
                <DialogTitle>
                    {mode==="add" ? " Add new" : " Edit"}
                </DialogTitle>
            <DialogContent>

            <DialogContentText>
                {title}
            </DialogContentText>

            <form>
                {
                    Object.keys(data).map((k) => (!k.startsWith("_")) && (
                        <TextField
                            label={k}
                            key={k}
                            name={k}
                            value={formData[k]}
                            fullWidth
                            onChange={handleChange}
                            margin="dense"
                            disabled={k==='id' ? true : false}
                        />
                    ))
                }
            </form>

            </DialogContent>
            <DialogActions>
            <Button onClick={handleSubmit} color="primary" autoFocus>
                {mode}
            </Button>
            <Button onClick={handleClose} color="secondary">
                cancel
            </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}