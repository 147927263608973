import { Alert, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convUnixDate, refresh, sendDataToServer, time } from "../utils/functions";
import ClearIcon from '@mui/icons-material/Clear';
import ModalForm from "./ModalForm";


export default function TableShow({table, scheme, update}){

    const [data, setData] = useState({})

    const opTable = table.charAt(0).toUpperCase() + table.slice(1)

    useEffect(() => {
        const upd = parseInt(update)
        const fetchData = () => {
                sendDataToServer({op: "get"+opTable}).then(r => {
                    setData(r["data"])
                })
        };
        fetchData();

        if (upd > 0){
            const interval = setInterval(() => {
                fetchData();
            }, 1000 * parseInt(update));
            return () => clearInterval(interval);
        }

    // eslint-disable-next-line
    }, [opTable])



    function delRow(lid){
        const id = data[lid]["id"]
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Delete this row #"+id+"?")){
            sendDataToServer({op:"del"+opTable, id}).then(r => {
                if (r["status"] === "OK"){
                    // Создаем новый объект без удаляемого элемента
                    const updatedData = { ...data }; // Создаем копию объекта
                    delete updatedData[lid]; // Удаляем элемент по ключу
                    setData(updatedData); // Обновляем состояние
                }
            })
        }
    }



    function handleCchk(lid, state){
        var s = (state ? "1": "0")
        sendDataToServer({op: "enable"+opTable, id: data[lid]["id"], state: s}).then(r => {
            if(r["status"]==="OK"){
                data[lid]["enable"] = s
                setData({...data})
            }
        })
    }


    function editCol(t, d, lid, col){
        var v = prompt("New value '"+col+"':", d[lid][col])
        if (v !== null && v !== "" && v !== " "){
            sendDataToServer({op: "editCol", table:t, id: d[lid]["id"], col, val:v}).then(r => {
                if(r["status"] === "OK"){
                    data[lid][col] = v
                    setData({...data})
                }
            })
        }
    }


    function clearTime(t, d, lid, col){
        const v = time()
        sendDataToServer({op: "editCol", table:t, id: d[lid]["id"], col, val:v}).then(r => {
            if(r["status"] === "OK"){
                data[lid][col] = v
                setData({...data})
            }
        })
    }




    return (

        <>
            {Object.keys(data).length ? (
            <table width="100%" className="table_style"> 
                <thead><tr>
                    {
                        Object.entries(scheme).map(([k, v], kk) => (
                            <th key={kk}>{v}</th>
                        ))
                    }
                    <th style={{textAlign:"right"}}>OPTION</th>
                </tr></thead>
                <tbody>
                {Object.keys(data).map((v,k) => (
                        <tr key={k}>
                            {
                                Object.entries(scheme).map(([kk, vv], ii) => (
                                    <td key={ii}>{(kk === "enable") ? (
                                        <Checkbox size="small"
                                            checked={parseInt(data[v][kk])===1}
                                            style={{padding:"1px"}}
                                            onChange={e => handleCchk(v, e.target.checked)} 
                                        />
                                    ) : kk.startsWith("time_") ?
                                        (<span className="tipa-a" onClick={() => clearTime(table, data, v, kk)}>{convUnixDate(data[v][kk])}</span>) :
                                        (<span className='tipa-a' onClick={()=>editCol(table, data, v, kk)}>{data[v][kk]}</span>) }
                                    </td>
                                ))
                            }
                            <td align="right">
                                <ModalForm mode="edit" data={data[v]} table={table} onSave={e => e && refresh("")} />
                                <ClearIcon  className="tipa-a sm" onClick={() => delRow(v)} />
                            </td>
                        </tr>
                ))}
                </tbody>
            </table>) : (<Alert severity="info">No data in table</Alert>)}
        
        </>

    )

}