import { 
    Filter1, Filter2, Filter3, Filter4, Filter5, 
    Filter6, Filter7, Filter8, Filter9, Filter9Plus, 
    FilterNone 
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";

const filterIcons = [
    Filter1, Filter2, Filter3, Filter4, Filter5, 
    Filter6, Filter7, Filter8, Filter9
];

export default function ServiceCaids({ caid }) {
    const c = caid.split(",");

    var r

    if (c[0] === "FTA") {
        r = <FilterNone fontSize="small" />;
    }else{
        const IconComponent = c.length > 9 ? Filter9Plus : filterIcons[c.length - 1];
        r = <IconComponent fontSize="small" />
    }

    return <Tooltip title={caid}>{r}</Tooltip>
}
