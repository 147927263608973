import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {  sendDataToServer } from "../utils/functions";
import { Alert, Paper } from "@mui/material";
import ServiceCaids from "../components/ServiceCaids";
import ServiceTypeId from "../components/ServiceTypeId";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ServicesPage(){
    const { '*': wildcard } = useParams();
    const [data, setData] = useState([])
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        sendDataToServer({op:"a", opt:"getServices", tpid: parseInt(wildcard)}).then(r=>{
            setData(r)
            setShowAlert(true)
        })
    // eslint-disable-next-line
    }, [])


    function downloadTs(v){
        window.open(process.env.REACT_APP_DOWNLOADTS + "?f="+wildcard+"/"+v["id"]+"/"+v["time_scan"]+".ts", '_blank');
    }

    

    return (
        <Paper style={{marginTop:"30px", padding:"20px 30px"}}>
            {data.length>0 ? (

            <table width="100%" className="table_style">
                <thead>
                    <tr style={{fontSize:"14px"}}>
                        <th style={{width:"50px"}}>#</th>    
                        <th>Name</th>    
                        <th>Provider</th>    
                        <th>PNR</th>    
                        <th>CAID</th>    
                        <th>Type</th>    
                        <th>TS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((v, k) => (
                            <tr key={k} className={v["caid"]==="FTA" ? "colorGreen" : ""}>
                                <td>{k+1}</td>
                                <td>{v["chname"]}</td>
                                <td>{v["provider"]}</td>
                                <td>{v["pnr"]}</td>
                                <td><ServiceCaids caid={v["caid"]} /></td>
                                <td><ServiceTypeId id={v["type"]} /></td>
                                <td><span className="tipa-a"><FileDownloadIcon onClick={() => downloadTs(v)} /></span></td>
                            </tr>
                        ))
                    }
                </tbody>

            </table>
            ) :   (showAlert && <Alert severity="error">No data for display</Alert>)
        }
        </Paper>
    )
}