import axios from 'axios'
//import dotenv from 'dotenv'
import { MD5 } from 'crypto-js';
import Cookies from 'js-cookie';


export const sendDataToServer = async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_PATH, data);
      if (response.statusText === "OK" || response.status === 200){
        if ("data" in response.data){
            const tmp1 = response.data
            const tmp2 = []
            for( const k in tmp1.data) { // ещё один раз в дату
              if (Number(k) > 0){
                tmp2.push(tmp1.data[k])  
              }else{
                if ("error" in tmp1){
                  console.error(tmp1.error)
                }
                if ("error" in tmp1.data){
                  console.error(tmp1.data.error)
                }
                return tmp1.data
              }
            }
            return tmp2
        }
        return response.data
      }else{
        return {statusText: response.statusText, status:response.status}
      }
    } catch (error) {
      //alert(error.message)
      console.error('Error request data:', error);
    }
  }; 


export  function toHexString(num) {
  return "0x" + num.toString(16).toUpperCase().padStart(2, '0');
}


export function refresh(url){
  window.location.href=url
}


export  function md5hash(str) {
  return MD5(str).toString();
}



export  function arrayChunk(arr, chunkSize) {
    const chunkedArray = [];
    
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
}



export function ins(str, search){
  if (!search || search.trim() === "") {
      return str; // Вернуть исходную строку, если поисковая строка пустая
  } else {
      const exp = new RegExp(search, "gi");
      const replacedStr = str.replace(exp, "<span class='ins'>"+search+"</span>");
      return <span dangerouslySetInnerHTML={{ __html: replacedStr }} />;    
  }
}



export  function convUnixDate(unixTime){

    if(parseInt(unixTime)<=1 || unixTime === "") return ""

    unixTime = parseInt(unixTime)
    if (unixTime <= 10) return ""
    const date = new Date(unixTime * 1000)
    const dateNow = new Date()
    const timeLast = (dateNow.getTime()/1000)-86400
    const dateLast = new Date(timeLast * 1000)
    const timeTomor = (dateNow.getTime()/1000)+86400
    const dateTomor = new Date(timeTomor * 1000)
    const Y = date.getFullYear();
    let m = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    let d = date.getDate();
    let H = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();
    var ddate = ""
    if(dateNow.getFullYear() === Y &&
            (dateNow.getMonth()+1) === m &&
                    dateNow.getDate() === d){
            ddate = "Today"
        }else if(dateLast.getFullYear() === Y &&
                    (dateLast.getMonth()+1) === m &&
                        dateLast.getDate() === d){
             ddate = "Yesterday"
        }else if (dateTomor.getFullYear() === Y &&
                    (dateTomor.getMonth()+1) === m &&
                        dateTomor.getDate() === d){
             ddate = "Tomorrow"
        }else{
          if(m<10) m = "0"+m
          if(d<10) d = "0"+d
          ddate = d+"."+m+"."+Y
        }


        if(H<10) H = "0"+H
        if(i<10) i = "0"+i
        if(s<10) s = "0"+s

    return ddate + " " + H + ":" + i + ":" + s
}


export function time() {
  return Math.floor(new Date().getTime() / 1000);
};


export function mkSerialNo(serial){
  serial = serial.toString().split('').reverse().join('');
  serial = serial.replace(/(\d)(?=(\d{3})+$)/g, '$1-');
  return serial.split('').reverse().join('');
}

export function isUser(){
  return Cookies.get('user')
}