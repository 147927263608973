import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#666',
    },
  },
});

if (window.location.protocol === 'http:' && !window.location.href.includes("localhost") && !window.location.href.includes("192.168") && !window.location.href.includes("10.8"))  {
  window.location.href = window.location.href.replace('http:', 'https:');
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}><App /></ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

