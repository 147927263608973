import React from 'react'
import { Route, Routes } from 'react-router-dom';
import MainPage from '../pages/MainPage'
import LogsPage from '../pages/LogsPage'
import ConfigPage from '../pages/ConfigPage'
import LoginPage from '../pages/LoginPage'
import FreqPage from '../pages/FreqPage'
import LogoutPage from '../pages/LogoutPage'
import NotFoundPage from '../pages/NotFoundPage'
import { isUser } from './functions';
import UsalsPage from '../pages/UsalsPage';
import ServicesPage from '../pages/ServicesPage';




export default function RoutesUser(props){


    return(
        <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/main' element={<MainPage />} />
            <Route path='/services/*' element={<ServicesPage />} />

            {isUser() === "admin" && <>
                <Route path='/usals' element={<UsalsPage />} />
                <Route path='/frequencies' element={<FreqPage />} />
                <Route path='/logs' element={<LogsPage />} />
                <Route path='/config' element={<ConfigPage />} />
            </>}
            
            <Route path='/login' element={<LoginPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    );
}
