import React from 'react'
import RedirectHome from '../utils/RedirectHome'
import { Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie'



export default function LogoutPage(props){
    var opt = {}
    if(process.env.REACT_APP_DEBUG==="0"){
        opt.secure = true
        opt.sameSite = "Strict"
        opt.domain = "dvbscan.com"
        opt.path = "/"
    }
    Cookies.remove('user',opt)
    Cookies.remove('rank',opt)
    Cookies.remove('hash',opt)

    return(
        <>

            <Paper style={{marginTop:"30px", padding:"20px 30px"}}>
                <Typography variant="h4">Exit from dashboard..</Typography>
                <RedirectHome />
            </Paper>
        </>
    )
}