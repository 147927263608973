import TvIcon from '@mui/icons-material/Tv';
import HdIcon from '@mui/icons-material/Hd';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import RadioIcon from '@mui/icons-material/Radio';
import StorageIcon from '@mui/icons-material/Storage';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import React from "react";
import { toHexString } from '../utils/functions';
import { Tooltip } from '@mui/material';


export default function ServiceTypeId({id}){



    const types = [
        { id : 1, desc : "Digital television service", icon: (<TvIcon fontSize='small' />)},
        { id : 2, desc : "Digital radio sound service", icon: (<RadioIcon fontSize='small' />)},
        { id : 3, desc : "Teletext service", icon: (<StorageIcon fontSize='small' />)},
        { id : 4, desc : "NVOD reference service", icon: (<StorageIcon fontSize='small' />)},
        { id : 5, desc : "NVOD time-shifted service", icon: (<StorageIcon fontSize='small' />)},
        { id : 6, desc : "Mosaic service", icon: (<StorageIcon fontSize='small' />)},
        { id : 7, desc : "FM Radio service", icon: (<RadioIcon fontSize='small' />)},
        { id : 8, desc : "DVB SRM service", icon: (<StorageIcon fontSize='small' />)},
        { id : 10, desc : "Advanced codec digital radio sound service", icon: (<RadioIcon fontSize='small' />)},
        { id : 11, desc : "Advanced codec mosaic service", icon: (<StorageIcon fontSize='small' />)},
        { id : 12, desc : "Data broadcast service", icon: (<StorageIcon fontSize='small' />)},
        { id : 13, desc : "Common Interface Usage", icon: (<StorageIcon fontSize='small' />)},
        { id : 14, desc : "RCS Map", icon: (<StorageIcon fontSize='small' />)},
        { id : 15, desc : "RCS FLS", icon: (<StorageIcon fontSize='small' />)},
        { id : 16, desc : "DVB MHP service", icon: (<StorageIcon fontSize='small' />)},
        { id : 17, desc : "MPEG-2 HD digital television service", icon: (<HdIcon fontSize='small' />)},
        { id : 22, desc : "Advanced codec SD digital television service", icon: (<TvIcon fontSize='small' />)},
        { id : 23, desc : "Advanced codec SD NVOD time-shifted service", icon: (<TvIcon fontSize='small' />)},
        { id : 24, desc : "Advanced codec SD NVOD reference service", icon: (<TvIcon fontSize='small' />)},
        { id : 25, desc : "Advanced codec HD digital television service", icon: (<HdIcon fontSize='small' />)},
        { id : 26, desc : "Advanced codec HD NVOD time-shifted service", icon: (<HdIcon fontSize='small' />)},
        { id : 27, desc : "Advanced codec HD NVOD reference service", icon: (<HdIcon fontSize='small' />)},
        { id : 31, desc : "Summary of signalling for different bitstream", icon: (<StorageIcon fontSize='small' />)},
        { id : 32, desc : "HEVC UHD digital television service", icon: (<HighQualityIcon fontSize='small' />)},        
        { id : 33, desc : "VVC digital television service", icon: (<HighQualityIcon fontSize='small' />)},        
        { id : 34, desc : "AVS3 digital television service", icon: (<HighQualityIcon fontSize='small' />)},        
        { id : 36, desc : "Advanced codec HD digital television service", icon: (<HighQualityIcon fontSize='small' />)},
    ]


    // Найдем тип по id
    const serviceType = types.find(type => parseInt(id) === type.id);

    return (
        <>
            {
                serviceType ? (
                    <Tooltip title={toHexString(parseInt(id)) + ": " + serviceType.desc}>{serviceType.icon}</Tooltip>
                ) : (
                    <Tooltip title={toHexString(parseInt(id)) + ": Unknown"}>{(<HelpCenterIcon fontSize='small' />)}</Tooltip>
                )
            }
        </>
    );

}