import { Alert} from "@mui/material";
import React, { useEffect, useState } from "react";
import { convUnixDate, sendDataToServer } from "../../utils/functions";
import { Link } from "react-router-dom";
import DataObjectIcon from '@mui/icons-material/DataObject';
import ModalJson from "../ModalJson";


export default function TableShow(){

    const [data, setData] = useState([])
    const [modalJson, setModalJson] = useState(0)

    useEffect(() => {
        const fetchData = () => {
            sendDataToServer({op:"a", opt:"getLastScanTp"}).then(r => {
                setData(r)
            })
        };
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 1500);
        return () => clearInterval(interval);
    }, [])






    return (

        <>
            {data.length > 0 ? (
            <table width="100%" className="table_style"> 
                <thead><tr>
                    <th>SAT</th>
                    <th>Type</th>
                    <th>Transponder</th>
                    <th><center>Services</center></th>
                    <th>Start</th>
                    <th>Stop</th>
                    <th>Scan</th>
                </tr></thead>
                <tbody>
                {
                    data.map((v,k) => (
                        <tr key={k} className={(v["busy"] === "1" ? "freq-busy" : "")}>
                            <td>{parseInt(v["sat"]) > 0 ? v["sat"]+"E" : v["sat"].replace("-", "")+"W"}</td>
                            <td>{v["type"]}</td>
                            <td>{v["tp"]}</td>
                            <td align="center"><Link target="_blank" rel="noopener noreferrer" to={"/services/"+v["id"]}>{v["cnt"]}</Link></td>
                            <td>{convUnixDate(v["time_start"])}</td>
                            <td>{convUnixDate(v["time_stop"])}</td>
                            <td><DataObjectIcon className="tipa-a" fontSize={"small"} onClick={() => setModalJson(v["id"])} /></td>
                        </tr>
                        )
                    )}
                </tbody>
            </table>) : (<Alert severity="info">No data in table</Alert>)}


            <ModalJson id={modalJson} onClose={e => setModalJson(e)} />
            
        
        </>

        

    )

}