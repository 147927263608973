import React, { useEffect, useState } from 'react'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { convUnixDate, sendDataToServer } from '../utils/functions';
import {Box, Button, FormHelperText, TextField, Grid } from '@mui/material';
import Cookies from 'js-cookie'
import RestartAltIcon from '@mui/icons-material/RestartAlt';


export default function ConfigPage(props) {
    const [conff, setConff] = useState([]);
    const [ecmkey, setEcmkey] = useState("")
    const [ecmerr, setEcmerr] = useState(false)
    const [uptime, setUptime] = useState("")
    const [dbsize, setDbsize] = useState("")

    

    useEffect(() => {
        getUptime()
        sendDataToServer({ 'op': "getConf" })
            .then(r => {
                setConff(r);
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(getUptime, 1000);
        return () => clearInterval(intervalId);
    }, [])

    function getUptime(){
        sendDataToServer({ 'op': "getUptime"})
        .then(r => {
            setUptime(r.status)
            setDbsize(r.dbsize)
        })
    }



    function handleSubmit(e){
        e.preventDefault();
        sendDataToServer({
            'op' : "setConf",
            'formData' : conff
        }).then(() => {
            //if(r.status === "OK"){
                Cookies.remove('user')
                Cookies.remove('rank')
                Cookies.remove('hash')
                window.location.href="/"
            //}else{
            //   alert(r.status)
            //} 
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        setConff(prevConff => {
            return prevConff.map(confItem => {
                if (confItem.key === name) {
                    if(confItem.key === "ecm_key" && value.length <= 64){
                        setEcmkey(value)
                        //handleChangeEcm()
                        //console.log(ecmkey, value)
                        setEcmerr(false)
                    }
                    return { ...confItem, value: value };
                } else {
                    return confItem;
                }
            });
        });
    };


















    // ██████╗ ███████╗████████╗██╗   ██╗██████╗ ███╗   ██╗
    // ██╔══██╗██╔════╝╚══██╔══╝██║   ██║██╔══██╗████╗  ██║
    // ██████╔╝█████╗     ██║   ██║   ██║██████╔╝██╔██╗ ██║
    // ██╔══██╗██╔══╝     ██║   ██║   ██║██╔══██╗██║╚██╗██║
    // ██║  ██║███████╗   ██║   ╚██████╔╝██║  ██║██║ ╚████║
    // ╚═╝  ╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝
                                                        


    return (
        <>
            <Grid container>
                <Grid item xs={7}>
                    <h2>
                        <SettingsSuggestIcon style={{ marginRight: "10px" }} />
                        Configuration server
                    </h2>
                </Grid>
                <Grid item xs={5}>
                    <Grid container style={{marginTop:"20px"}}>
                        <Grid item xs={4} style={{marginTop:"15px", color:"gray", font:"11px/1.1em Verdana"}}>
                            {dbsize}
                        </Grid>
                        <Grid item xs={5} style={{marginTop:"10px"}}>
                            Uptime: <b>{uptime}</b>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" color="error" onClick={
                                () => {
                                    sendDataToServer({'op':"restart"})
                                    window.location.href=""
                                }
                            }><RestartAltIcon /> RESTART</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <hr />

            <form onSubmit={handleSubmit} >
            <table width='100%'>
                <tbody>
                    {
                        conff.map((v, index) => (
                             <tr key={"kk+" + index}>
                                <td
                                    align="right"
                                    valign="top"
                                    style={{paddingTop: "30px"}}
                                    width="30%">
                                        {v.descr}
                                </td>
                             <td style={{padding:"10px 0 5px 10px"}}>
                             <Box
                                sx={{
                                    width: 500,
                                    maxWidth: '100%',
                                }}
                                >{
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        name={v.key}
                                        label={v.key}
                                        variant="outlined"
                                        value={v.key==="ecm_key" ? ecmkey : conff[index].value} 
                                        onBlur={handleChange}
                                        onChange={handleChange}
                                        error={v.key==="ecm_key" ? ecmerr : false} 
                                    />
                                }
                                    <FormHelperText>{
                                        (v.value !== v.last && v.last !== "") && "Last value: " + v.last + ". Change " + convUnixDate(Number(v.e_time)>0 ? v.e_time : 0)
                                    }</FormHelperText>
                                </Box>
                             </td>
                             </tr>
                        ))
                    }
                    <tr><td></td><td style={{padding:"30px 10px"}}>
                        <Button variant="contained" type="submit">{
                            "Save & restart"
                        }</Button>
                    </td></tr>
                </tbody>
            </table>
            </form>
        </>
    );
}